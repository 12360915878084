<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Layer </strong><small>Tambah Data Baru</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputLayer">
            <CRow>
              <CCol sm="12">
                <CInput
                  v-model="nama_layer"
                  label="Layer"
                  placeholder="Input Layer"
                />
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Kota/Kabupaten</label>
                  <select class="form-control" @change="cariKecamatan()" v-model="id_kategori">
                    <option value="" disabled selected>-- Pilih Kota/Kabupaten --</option>
                    <option v-for="r in kategoris" :value="r.id_kategori">{{r.nama_kategori}} </option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Kecamatan</label>
                  <select class="form-control" @change="cariKelurahan()" v-model="id_subkategori">
                    <option value="" disabled selected>-- Pilih Kecamatan --</option>
                    <option v-for="r in subkategori" :value="r.id_kategori">{{r.nama_kategori}} </option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Kelurahan/Desa</label>
                  <select class="form-control" v-model="id_subsubkategori">
                    <option value="" disabled selected>-- Pilih Kelurahan/desa --</option>
                    <option v-for="r in subsubkategori" :value="r.id_kategori">{{r.nama_kategori}} </option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Infrastruktur</label>
                  <select class="form-control" v-model="infrastruktur">
                    <option value="" disabled selected>-- Pilih Tipe Infrastruktur --</option>
                    <option v-for="r in infrastrukturs" :value="r.toLowerCase()">{{r}} </option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">             
                <div class="form-group">
                <label>Sub Infrastruktur</label>
                  <select class="form-control" v-model="sub_infrastruktur">
                    <option value="" selected>-- Pilih Tipe Infrastruktur --</option>
                    <option v-for="r in subinfrastrukturs" :value="r.toLowerCase()">{{r}} </option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                <label>Tampilkan Label</label>
                <select v-model="tampilkan_label" placeholder="-- Pilihan --" class="form-control">
                  <option value="" disabled selected>-- Pilihan --</option>
                  <option value="1">Ya</option>
                  <option value="0">Tidak</option>
                </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                <label>Jenis</label>
                <select v-model="jenis_layer" placeholder="-- Pilihan --" class="form-control">
                  <option value="" disabled selected>-- Pilihan --</option>
                  <option value="1">Point</option>
                  <option value="2">Line</option>
                  <option value="3">Polygon</option>
                </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                <label>Publisitas</label>
                <select v-model="publisitas_layer" placeholder="-- Pilihan --" class="form-control">
                  <option value="" disabled selected>-- Pilihan --</option>
                  <option value="1">Ya</option>
                  <option value="0">Tidak</option>
                </select>
                </div>
              </CCol>
              <!-- <CCol sm="6">
                <div class="form-group">
                  <label>Icon</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange1($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol> -->
              <CCol sm="6">
                <div class="form-group">
                  <label>File Layer *file SHP yg sdh di.zip atau File .Geojson</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder2" aria-describedby="inputGroupFileAddon02">
                    <label class="custom-file-label" for="inputGroupFile02">{{placeholder2}}</label>
                  </div>
                </div>  
              </CCol>
            </CRow>          
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/layer">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {Datepicker },
  name: 'Forms',
  data () {
    return {
      nama_layer : "",
      nama_kategori : "",
      id_kategori : "",
      id_subkategori : "",
      id_subsubkategori : "",
      infrastruktur : "",
      sub_infrastruktur : "",
      tampilkan_label : "",
      jenis_layer : "",
      publisitas_layer : "",
      icon_layer_text : "",
      icon_layer : "",
      file_layer_text : "",
      file_layer : "",
      placeholder : "Pilih File",
      placeholder2 : "Pilih File",
      selected : [], // Must be an array reference!
      kategoris : [],
      subkategori : [],
      subsubkategori : [],
      infrastrukturs : ['Drainase','Jalan','Jembatan'],
      subinfrastrukturs : ['Jalan Baik','Jalan Rusak','Jalan Tanah', 'Jalan Beton', 'Jalan Aspal', 'Jalan Non Status'],
      show: true,
      modalError: false,
    }
  },
  mounted(){
    this.loadData();
  },
  methods: {

    cariKecamatan: function(){
      axios.get(process.env.VUE_APP_BASE_URL+"kategoris/filter?level=2&id_parent="+this.id_kategori)
        .then((response) => {
              this.subkategori=response.data;
        });
    },
    cariKelurahan: function(){
      axios.get(process.env.VUE_APP_BASE_URL+"kategoris/filter?level=3&id_parent="+this.id_subkategori)
        .then((response) => {
              this.subsubkategori=response.data;
        });
    },
    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"kategoris/filter?level=1&id_parent=0")
        .then((response) => {
              this.kategoris=response.data;
        });
    },

    onFileChange1(event) {
      var fileData = event.target.files[0];
      this.icon_layer_text = fileData.name;
      this.icon_layer = fileData;
      this.placeholder = fileData.name;
    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.file_layer_text = fileData.name;
      this.file_layer = fileData;
      this.placeholder2 = fileData.name;
    },
    
    inputLayer: function(event){
      
      const formData = new FormData();

        if(this.file_layer_text){
          formData.append('file_layer', this.file_layer, this.file_layer.name) 
          formData.append('file_layer_text', this.file_layer.name)
        }     
        if(this.icon_layer_text){
          formData.append('icon_layer', this.icon_layer, this.icon_layer.name) 
          formData.append('icon_layer_text', this.icon_layer.name)
        }    
        
        formData.append('nama_layer', this.nama_layer)
        formData.append('file_layer', this.file_layer)
        formData.append('id_kategori', this.id_kategori)
        formData.append('id_subkategori', this.id_subkategori)
        formData.append('id_subsubkategori', this.id_subsubkategori)
        formData.append('infrastruktur', this.infrastruktur)
        formData.append('sub_infrastruktur', this.sub_infrastruktur)
        formData.append('tampilkan_label', this.tampilkan_label)
        formData.append('jenis_layer', this.jenis_layer)
        formData.append('publisitas_layer', this.publisitas_layer)

      axios.post(process.env.VUE_APP_BASE_URL+"layer", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/layer');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>